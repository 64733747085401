@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}
.top-nav .navbar {
  padding: 0;
}

.top-nav .navbar {
  padding: 0;
  border-bottom: 1px solid #00000017;
  box-shadow: none;
}

.plain-button .input-group .btn {
  height: 100%;
  border-radius: 0;
}

.border-none {
  border: none !important;
}
.plain-btn {
  background: none !important;
  color: inherit !important;
  border: none !important;
  box-shadow: none !important;
  border-right: 1px solid #dddddd;
  padding-right: 5px;
  text-transform: capitalize !important;
}

.plain-button .input-group-text {
  
  background-color: transparent;
  border: none;
}

.primary-text-color {
  color: rgb(59 130 246 );
}
.bg-primary-light {
  background: #e8f3ff !important;
}

.primary-bg-color {
  background: rgb(59 130 246 );
}

ol, ul {
  padding-left: 0;
}

.footer-links li {
  margin-top: 6px;
  color: #6d6d6d;
  /* font-weight: 500; */
}

.content-image {
  /* position: absolute; */
  /* z-index: 9999; */
}

.carousel .carousel-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 0;
  color: #fff;
  text-align: center;
}

.iconated-slider .icon {
  background: #fff;
  border-radius: 100%;
  padding: 7px;
  text-align: center;
  /* display: inline-block; */
  /* color: blue !important; */
}

.iconated-slider {
  display: inline-block;
}

.carousel-control-next, .carousel-control-prev {
  width: auto !important;
}

.products-selector.nav-tabs .nav-item.show .nav-link, .products-selector.nav-tabs .nav-link.active {
  border-color: transparent;
  border-bottom: 2px solid rgb(59 130 246 );
  color: rgb(59 130 246 );
}

.products-selector .nav-link {
  padding-left: 0 !important;
  text-transform: capitalize !important;
  font-weight: bold !important;
  font-size: 18px !important;
}

.products-selector.nav-tabs .nav-item.show .nav-link, .products-selector.nav-tabs .nav-link.active {
  padding-left: 0;
}

.products-selector .nav-link:hover {
  background-color: transparent !important;
  border-color: transparent;
  border-bottom: 2px solid rgb(59 130 246 ) !important;
  color: rgb(59 130 246 ) !important;

}


.featured-purchase {
  background: rgb(59 130 246 );
  color: #ffffff;
  padding: 13px 20px;
}

.trending .featured-purchase {
  background: #ffffff;
  color: rgb(59 130 246 );
}

.featured-items {
  background-image: url('./assets/backgrounds/features_product_bg.jpg');
  background-position: center;
  background-size: cover;
  border-top: 1px solid #fff;
  padding-top: 100px;
  padding-bottom: 100px;
}

.featured-item {
  background: #ffffff;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 15px;
  box-shadow: 0px 3px 10px 0px rgba(80, 177, 249, 0.21);
}

.featured-item.trending {
  color: #ffffff;
  background: rgb(59 130 246 );
}
.featured-item img {
  max-width: 100%;
  width: 100%;
  max-height: 160px;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  
  background: black;
}

.page-header {
  background: rgb(59 130 246 );
  border-bottom: 1px solid rgb(232, 243, 255);
  color: white;
}
.page-header h1 {
  font-size: 23px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
}

.checked-listing .form-check-input:disabled~.form-check-label,
.checked-listing .form-check-input[disabled]~.form-check-label,
.checked-listing .form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 1;
}

.checked-listing .form-check {
  margin-bottom: 10px;
  line-height: 1.7;
  font-size: 18px !important;
  color: #888888;
}

.intro-block {
  background: #e8f3ff;
  padding-top: 65px;
  padding-bottom: 65px;
  background-image: url('./assets/backgrounds/intro-block.png');
  background-position: left bottom;
  background-repeat: no-repeat;
  /* background-size: cover; */

}

.std-btn {
  padding: 10px 20px !important;

}
.btn.btn-primary {
  background-color: #3D85C6;
  border-color: #3D85C6;
}
.service-item {
  background: #ffffff;
  padding: 15px 20px;
  border-radius: 5px;
  margin-top: 25px;
}

.item-text h5 {
  text-transform: capitalize;
}

.carousel-cta {
  height: 200px;
  width: 200px;
  display: block;
  line-height: 200px;
  text-align: center;
}

.title-font {
  font-family: "KoHo", sans-serif;
  font-weight: 700;
  font-style: normal;
}